<template>
  <q-banner
    v-if="cta.requirement === 'permission'"
    rounded
    class="banner-warning"
  >
    <span>
      {{ $t('shop.cta.permission.over') }}
    </span>
    <i18n-t keypath="shop.cta.permission.link" tag="span" scope="global">
      <template #link>
        <!-- TODO: SHOP change link -->
        <router-link
          :to="`/shop/search/features/${cta.permission.name}`"
          class="text-link"
        >
          {{ $t('shop.cta.browse').toLowerCase() }}
        </router-link>
      </template>
    </i18n-t>
  </q-banner>

  <q-banner
    v-else-if="cta.requirement === 'limitation' && limitationType === 'close'"
    rounded
    class="banner-info"
  >
    <i18n-t keypath="shop.cta.limitation.close" tag="span" scope="global">
      <template #values>
        {{ cta.limitation_object?.message }}
      </template>
      <template #limit>
        {{ cta.limitation_current_value }}
      </template>
      <template #currentValue>
        {{ cta.limitation_needed_value }}
      </template>
    </i18n-t>
    <i18n-t keypath="shop.cta.limitation.link" tag="span" scope="global">
      <template #link>
        <!-- TODO: SHOP change link -->
        <router-link
          :to="`/shop/search/features/${cta.limitation_type}`"
          class="text-link"
        >
          {{ $t('shop.cta.browse').toLowerCase() }}
        </router-link>
      </template>
    </i18n-t>
  </q-banner>

  <q-banner
    v-else-if="cta.requirement === 'limitation'"
    rounded
    :class="limitationType === 'reached' ? 'banner-warning' : 'banner-negative'"
  >
    <i18n-t
      :keypath="
        limitationType === 'reached'
          ? 'shop.cta.limitation.reached'
          : 'shop.cta.limitation.over'
      "
      tag="span"
      scope="global"
    >
      <template #values>
        {{ cta.limitation_object?.message }}
      </template>
      <template #limit>
        {{ cta.limitation_current_value }}
      </template>
    </i18n-t>
    <i18n-t keypath="shop.cta.limitation.link" tag="span" scope="global">
      <template #link>
        <!-- TODO: SHOP change link -->
        <router-link
          :to="`/shop/search/features/${cta.limitation_type}`"
          class="text-link"
        >
          {{ $t('shop.cta.browse').toLowerCase() }}
        </router-link>
      </template>
    </i18n-t>
  </q-banner>
</template>

<script setup lang="ts">
import { CallToActionType } from 'types/common/cta'
import { computed } from 'vue'

// PROPS
const props = defineProps<{
  cta: CallToActionType
}>()

const limitationType = computed<'close' | 'reached' | 'over'>(() => {
  if (props.cta.requirement === 'limitation') {
    return props.cta.limitation_needed_value <
      props.cta.limitation_current_value
      ? 'close'
      : props.cta.limitation_current_value === props.cta.limitation_needed_value
        ? 'reached'
        : 'over'
  } else return 'close'
})
</script>
