<template>
  <q-slide-transition @show="isShown = true" @hide="isShown = false">
    <icon-card
      v-if="
        errorStore.backErrors[errorType] || errorStore.frontErrors[errorType]
      "
      icon="fa-solid fa-triangle-exclamation"
      icon-size="sm"
      :center="false"
      class="text-negative animated"
      :class="animationClass"
      :style="{ borderColor: 'var(--q-negative)' }"
      bordered
      data-test="generalError"
    >
      <div class="flex items-center full-height">
        {{
          errorStore.backErrors[errorType] || errorStore.frontErrors[errorType]
        }}
      </div>
    </icon-card>
  </q-slide-transition>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

import { useErrorStore } from 'stores/common/error'

import IconCard from 'components/common/ui/card/IconCard.vue'

const errorStore = useErrorStore()

// PROPS
const props = withDefaults(
  defineProps<{
    errorType?: string
    animate?: boolean
  }>(),
  { errorType: 'generalError' }
)

const isShown = ref(false)

const animationClass = ref<string | null>(null)

watch(
  () => props.animate,
  () => {
    if (isShown.value) {
      // Add animation class
      animationClass.value = 'errorAnime'
      // Remove it after animation
      setTimeout(() => {
        animationClass.value = null
      }, 360)
    }
  }
)

defineExpose({
  isShown,
})
</script>

<style lang="scss" scoped>
.errorAnime {
  animation: q-field-label 0.36s;
}
</style>
